import React, { Fragment } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Layout } from "../../components/Layout"
import { AffiliateList } from "../../components/AffiliateList"
import { SEO } from "../../components/SEO"
import { ReviewsCMS } from "../../components/ReviewsCMS"
import { BeforeAndAfters } from "../../components/BeforeAndAfters"
import styles from "./product.module.css"
import containerClasses from "../../styles/container.module.css"
import helpers from "../../styles/helpers.module.css"
import doctify from "../../assets/images/doctify-logo.svg"
import buttons from "../../styles/buttons.module.css"
import starRate from "../../assets/images/star_rate.svg"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    title,
    excerpt,
    image,
    imageAlt,
    imageTitle,
    type,
    seoTitle,
    metaDescription,
    bottomBody,
    reviews,
    beforeAndAfters,
  } = frontmatter
  return (
    <Layout page={type}>
      <SEO title={seoTitle || title} description={metaDescription || excerpt} />
      <Helmet>
        <link rel="preload" href={image} as="image" />
      </Helmet>
      <div className="bg-white">
        <div
          className={`${containerClasses.container} pt-16 md:pt-24 lg:pb-12 pb-8`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-span-6">
            <p className="capitalize overline">
              {type.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </p>
            <h1 className="mt-0 mb-4 fluid-display-02">{title}</h1>
            <p className="mb-0 font-sans text-md">{excerpt}</p>
          </div>
          <div className="flex flex-col col-span-4 mt-0 md:flex-row lg:mt-20 lg:content-center md:col-span-8 lg:col-span-5 lg:col-start-8">
            <Link
              className={`flex items-center justify-center text-center md:flex-grow lg:h-12 lg:grow-0 md:mr-2 md:mt-2 ${buttons.btnLargeExpressive}`}
              to="/contact/"
            >
              <span className="block">Book now</span>
            </Link>
            <a
              className={`flex items-center justify-center text-center md:flex-grow lg:grow-0 lg:h-12 mt-4 md:mt-2 ${buttons.btnLargeExpressiveOutline}`}
              href="#faqs"
            >
              <span className="block">Read FAQ&apos;s</span>
            </a>
          </div>
          <div className="flex flex-row items-center content-center col-span-4 mt-0 mb-0 lg:mb-0">
            <div className="flex my-2 mr-2 text-doctifyBright">
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 mr-2"
                src={starRate}
                alt="Star"
              />
            </div>
            <p className="mb-0 mr-2">
              <span className="block font-bold">Rated 5/5</span>
            </p>
            <img
              loading="lazy"
              className="h-5 mr-1"
              src={doctify}
              alt="Doctify"
            />
          </div>
        </div>
      </div>
      <div className="headerHalfAndHalf">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-8 lg:col-span-12">
            <img
              className=""
              src={image}
              alt={imageAlt}
              title={imageTitle}
              width={1280}
              height={470}
            />
          </div>
        </div>
      </div>
      <div className="bg-white2">
        <div
          className={`${containerClasses.container} py-12 md:py-16 lg:py-20 xl:py-24`}
        >
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <div
              className={`${styles.productContent} lg:col-span-8 lg:col-start-3 xl:col-span-8 xl:col-start-3 `}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
      <Fragment>
        {reviews && reviews?.length > 0 ? (
          <div className="bg-white">
            <div className={containerClasses.container}>
              <div className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
                <ReviewsCMS reviews={reviews} />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
      <Fragment>
        {beforeAndAfters && beforeAndAfters?.length > 0 ? (
          <div className="bg-white2">
            <div className={containerClasses.container}>
              <div className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
                <BeforeAndAfters beforeAndAfters={beforeAndAfters} />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
      {/* Frequently Asked Questions */}
      <div id="faqs">
        <div className="bg-white">
          {bottomBody ? (
            <div
              className={`${containerClasses.container}  py-20 min-h-screen`}
            >
              <div className="top-0 z-10 content-center col-span-4 md:flex md:flex-col md:items-center lg:items-start lg:sticky md:col-span-6 md:col-start-2 lg:col-span-5 lg:col-start-2">
                <h2 className="mt-0 fluid-heading-05 lg:text-left md:text-center">
                  Frequently Asked Questions
                </h2>
                <p className="md:text-center lg:text-left ">
                  Dr Hawkes is available to address your inquiries. Should you
                  have questions, we kindly invite you to reach out.
                </p>
                <Link
                  className={`text-center block lg:inline-block md:w-auto mt-4 md:mt-2 ${buttons.btnLargeExpressiveOutline}`}
                  to="/contact/"
                >
                  <span className="block">Contact</span>
                </Link>
              </div>
              <div className="col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-7">
                <div
                  className={`${styles.productContent} col-span-4 md:col-span-4 lg:col-span-6 `}
                  dangerouslySetInnerHTML={{ __html: bottomBody }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="py-12 bg-brilliantWhite md:py-16 lg:py-20 xl:py-24">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-start-4">
            <h2 className="m-0 text-center fluid-display-04">
              Book a Consultation
            </h2>
            <p className="mt-4 text-center">
              If you would like to book a consultation, please use the form
              below. We will respond within 2 working days.
            </p>
          </div>
          <div
            className={`${helpers.iframe} col-span-4 md:col-span-8 lg:col-start-3`}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://crm.pabau.com/modules/lead-form/form.php?compid=10461"
            ></iframe>
          </div>
        </div>
      </div>

      <AffiliateList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        excerpt
        image
        imageAlt
        imageTitle
        type
        seoTitle
        metaDescription
        reviews {
          name
          date(formatString: "MMMM DD, YYYY")
          stars
          message
        }
        beforeAndAfters {
          image
        }
        bottomBody
      }
    }
  }
`
